<div class="container mt-3">
  <div class="row">
    <div class="col-12 mt-5">
      <div class="card shadow-sm mb-5 mt-3">
        <div class="card-body pt-4 pb-5" *ngIf="!company?.hasSigned">
          <div class="container-fluid" style="width: 85%;">
            <div class="row">
              <div class="col-12">
                <h4 class="title text-center">
                  {{'Cardboard_RepresentativeSignatureTitle' | translate}}
                </h4>

                <p class="mt-2 text-justify">
                  <span [innerHTML]="'Cardboard_RepresentativeSignatureText' | translate"></span>
                </p>
              </div>

              <div class="card-download mb-4" (click)="downloadFile()">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-2 my-auto">
                    <img class="img-fluid download-img" src="/assets/icons/pdf-file.png" alt="PDF File" />
                  </div>

                  <div class="col-12 col-sm-12 col-md-8 my-auto text-start">
                    <strong>{{company?.displayFileName}}</strong>
                  </div>

                  <div class="col-12 col-sm-12 col-md-2 my-auto text-center">
                    <section class="animate__animated animate__rubberBand animate__repeat-3">
                      <mat-icon class="download-icon">file_download</mat-icon>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <hr class="mb-3">

              <form class="mt-3 row" [formGroup]="formGroup" (ngSubmit)="uploadContract()">
                <div class="col-9 mx-auto">
                  <div class="mb-3 dashed-bottom-border">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-2 my-auto">
                        <strong class="step-form">1.</strong>
                      </div>

                      <div class="col-12 col-sm-12 col-md-10">
                        <label>{{'Cardboard_UploadRepresentativeContract' | translate}}</label>
                        <br><br>
                        <smartec-file-upload [message]="'General_DnDMessage'" [limit]="1"
                          (files)="getUploadedFiles($event)"></smartec-file-upload>
                      </div>
                    </div>
                  </div>

                  <button class="cardboard-button col-12" type="submit" [disabled]="formGroup.invalid">
                    <mat-icon class="mr-2">check_circle</mat-icon>
                    {{'General_UploadData' | translate}}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <section class="card-body pt-4 pb-5" *ngIf="company?.hasSigned">
          <app-representative-has-signed [representative]="company"
            [contractType]="formGroup?.value.contractType"></app-representative-has-signed>
        </section>
      </div>
    </div>
  </div>
</div>
