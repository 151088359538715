import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-company-created",
  templateUrl: "./company-created.component.html",
  styleUrls: ["./company-created.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class CompanyCreatedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
