<app-header [hideElements]="{ register: true }"></app-header>

<section class="container mb-5" style="margin-top: 9rem;">
  <div class="card shadow-lg">
    <div class="card-body">
      <div class="row">
        <div class="col-12 p-5">
          <form class="row" [formGroup]="formGroupBankAccount" (ngSubmit)="saveBankInfoAccount()">
            <div class="col-12 col-sm-12 col-md-8 mb-3">
              <section class="row mb-4">
                <div class="col-12 col-sm-12 col-md-6">
                  <small>{{'General_Name' | translate}}</small> <br>
                  <strong>{{company?.name}}</strong>
                </div>

                <div class="col-12 col-sm-12 col-md-3">
                  <small>{{'General_LegalForm' | translate}}</small> <br>
                  <strong>{{company?.legalForm}}</strong>
                </div>

                <div class="col-12 col-sm-12 col-md-3">
                  <small>{{'General_VAT' | translate}}</small> <br>
                  <strong>{{company?.vat}}</strong>
                </div>

                <div class="col-12 mt-3">
                  <hr>
                </div>
              </section>

              <div class="col-12 mb-3">
                <label [innerHTML]="'MM_Registration_Has_EU_VATID' | translate"></label> <br>
                <mat-slide-toggle formControlName="hasEuVat" color="primary"></mat-slide-toggle>
              </div>

              <div class="col-12 mb-3" *ngIf="formGroupBankAccount?.getRawValue()?.hasEuVat">
                <div class="row">
                  <div class="col-12 mb-3">
                    <label>
                      <span>{{'General_EUVAT' | translate}}</span> <span class="text-danger">*</span>
                    </label>

                    <input type="text" class="form-control" formControlName="euVat" />
                  </div>

                  <div class="col-12">
                    <div class="mb-3">
                      <label>
                        {{'General_BankAccountOwner' | translate}}<span class="text-danger">*</span>
                      </label>

                      <input type="text" class="form-control" formControlName="bankHolder" />
                    </div>
                  </div>

                  <section class="col-12">
                    <div class="mb-3">
                      <label>
                        IBAN <span class="text-danger">*</span>
                      </label>

                      <input type="text" class="form-control" formControlName="iban" (change)="getBankDetailsFromIban()"
                        oninput="this.value = this.value?.toUpperCase().replaceAll(' ', '')"
                        onpaste="this.value = this.value?.toUpperCase().replaceAll(' ', '')" />

                      <small class="text-danger"
                        *ngIf="formGroupBankAccount.get('iban')?.value !== '' && formGroupBankAccount.get('iban')?.errors !== null">
                        {{'General_IBANErrorMessage' | translate}}
                      </small>
                    </div>
                  </section>

                  <div class="col-12">
                    <div class="mb-3">
                      <label>
                        {{'General_BankName' | translate}}<span class="text-danger">*</span>
                      </label>

                      <ng-select formControlName="financialEntity" [clearable]="true" [searchable]="true"
                        [virtualScroll]="true" [items]="bankList" bindLabel="name" bindValue="name"
                        *ngIf="!isManualBank" (change)="setBic($event)" appendTo="body">
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                          <div class="row">
                            <div class="col-12">
                              <div class="card-title mb-0" style="font-weight: 400;">
                                {{item.name}}
                              </div>

                              <small class="text-muted">
                                <strong>BIC:</strong> {{item.bic}}
                              </small>
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>

                      <input type="text" class="form-control" formControlName="financialEntity" *ngIf="isManualBank"
                        oninput="this.value = this.value?.toUpperCase().replace(' ', '')" />
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="mb-3">
                      <label>
                        BIC <span class="text-danger">*</span>
                      </label>

                      <input type="text" class="form-control" formControlName="bic" />

                      <small class="text-danger"
                        *ngIf="formGroupBankAccount.get('bic')?.value !== '' && formGroupBankAccount.get('bic')?.errors !== null">
                        {{'General_BICErrorMessage' | translate}}
                      </small>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-4">
                        <div class="mb-3">
                          <label>
                            {{'General_NetInvoiceValue' | translate}}
                          </label>

                          <h2>

                            <span>
                              {{(currentYear > 2023 ? 10/1.17 : 10/1.16) | currency:'EUR' }}
                            </span>
                          </h2>
                        </div>
                      </div>

                      <div class="col-12 col-sm-12 col-md-4">
                        <div class="mb-3">
                          <label>
                            {{'General_LuxVAT' | translate}}
                          </label>

                          <h2>
                            <span *ngIf="formGroupBankAccount?.getRawValue()?.hasEuVat">
                              0% ({{0.0 | currency: 'EUR' }})
                            </span>
                            <span *ngIf="!formGroupBankAccount?.getRawValue()?.hasEuVat">
                              {{(currentYear > 2023 ? (10 - 10/1.17) : (10 - 10/1.16)) | number:'1.0-2' |
                              currency:'EUR'}} ({{currentYear > 2023 ? 17 : 16}}%)
                            </span>
                          </h2>
                        </div>
                      </div>

                      <div class="col-12 col-sm-12 col-md-4">
                        <div class="mb-3">
                          <label>
                            {{'General_EstimatedValueToBeInvoiced' | translate}}
                          </label>

                          <h2>
                            <span *ngIf="formGroupBankAccount?.getRawValue()?.hasEuVat">
                              {{(currentYear > 2023 ? 10/1.17 : 10/1.16) | currency:'EUR' }}
                            </span>
                            <span *ngIf="!formGroupBankAccount?.getRawValue()?.hasEuVat">
                              {{10 | currency:'EUR'}}
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mb-3" *ngIf="!disabled">
                    <label for="formFile" class="form-label">{{'General_Invoice' | translate}}</label>
                    <!-- <input class="form-control" type="file" id="formFile"
                      accept="application/msword, application/pdf, image/*" (change)="onFileChange($event)" /> -->

                    <section class="col-12">
                      <app-dnd-upload (selectedFile)="onFileChange($event)"></app-dnd-upload>
                    </section>
                  </div>
                </div>
              </div>

              <section class="col-12 mb-3 details-info details-info--warning"
                *ngIf="!formGroupBankAccount?.getRawValue()?.hasEuVat">
                <span [innerHTML]="'MM_Registration_GET_VIES_ID_DESCRIPTION' | translate"></span>
              </section>

              <section *ngIf="disabled">
                <button type="button" class="cardboard-button col-12 p-2 mt-3 mb-3" (click)="downloadInvoice()">
                  <mat-icon>file_download</mat-icon> {{'General_DownloadInvoice' | translate}}
                </button>
              </section>

              <section class="col-12" *ngIf="invoices?.length > 0">
                <label>{{'General_InvoiceList' | translate}}</label>
                <hr class="mt-1">

                <section class="table-responsive">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{{'General_DateCreated' | translate}}</th>
                        <th>{{'General_File' | translate}}</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let item of invoices; let i = index">
                        <td>
                          {{i+1}}
                        </td>

                        <td>
                          {{item.dateCreated | date:'medium'}}
                        </td>

                        <td>
                          <a class="text-primary"
                            [href]="cardboardURL + '/cardboard/' + item.companyId + '/invoice/' + item.fileName"
                            target="_blank">
                            {{item.fileName}} <mat-icon class="ms-2">link</mat-icon>
                          </a>
                        </td>

                        <td>
                          <mat-icon class="text-danger cursor--pointer" (click)="deleteInvoice(item, i)"
                            matTooltip="{{'General_Delete' | translate}}">delete</mat-icon>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <hr>
              </section>

              <div class="col-12 mt-3" *ngIf="!disabled">
                <br>
                <div class="mx-auto">
                  <div class="d-grid gap-2">
                    <button type="submit" class="cardboard-button p-4 mt-3"
                      [disabled]="formGroupBankAccount.invalid || !formGroupBankAccount?.value?.invoice || (formGroupBankAccount?.getRawValue()?.hasEuVat && !formGroupBankAccount?.value?.euVat)">
                      Guardar datos bancarios
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-4 my-auto details-info">
              <section>
                <span [innerHTML]="'MM_Registration_Bank_Details_Form_Description' | translate"></span>
              </section>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
