import { ITermCondition } from "src/app/models/parameters/ITermCondition";
import { PaginationService } from "../pagination/pagination.service";
import { ITermConditionsService } from "./terms-conditions";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { retry, catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import * as Sentry from "@sentry/angular-ivy";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class TermsConditionsService implements ITermConditionsService {
  private _apiURL: String = `${environment.crmURL}/api/termsConditions/`;

  constructor(
    private _http: HttpClient,
    private _paginationService: PaginationService,
    private _translateService: TranslateService
  ) {}

  getTermsConditions(): Observable<ITermCondition[]> {
    return this._http
      .get<ITermCondition[]>(`${this._apiURL}GetTermsConditions`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getTermConditionByLanguage(
    language: String = "en"
  ): Observable<ITermCondition[]> {
    language = this._translateService.currentLang;

    return this._http
      .get<ITermCondition[]>(
        `${this._apiURL}GetTermConditionByLanguage?language=${language}`
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = "";

    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
      Sentry.captureException(error.error);
    } else {
      // Get server-side error
      var id = Sentry.captureException(error);
      errorMessage = `Error Code: ${error.status}\nTraceID: ${id}\nMessage: ${error.message}`;
    }

    Swal.fire({
      title: `${this._translateService.instant("General_Error")}`,
      text: errorMessage,
      icon: "error",
    });

    return throwError(errorMessage);
  }
}
