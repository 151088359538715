<div class="section" id="AboutUs">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <h2 class="section-heading">
          {{'MM_Registration_AboutTheCartel' | translate}}
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-12">
        <nav class="categories">
          <ul class="portfolio_filter dark">
            <li>
              <a href="" class="active" data-filter="*">
                {{'MM_Registration_All' | translate}}
              </a>
            </li>

            <li *ngFor="let category of categories">
              <a href="" [attr.data-filter]="'.' + category">
                {{category}}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="row">
      @for(item of projects.slice(0, 3); track item; let index = $index) {
        <article class="col-sm-6 col-md-4 {{item.tags}}">
          <div class="box-image-4">
            <a [routerLink]="['/pages/projects/' + item.url]" [matTooltip]="item.title | translate">
              <div class="media">
                <img [src]="item.img" alt="project image" class="img-responsive">
              </div>

              <div class="body">
                <div class="content">
                  <h4 class="title">
                    {{item.title | translate}}
                  </h4>

                  <span class="category">
                    {{item.category | translate}}
                  </span>
                </div>
              </div>
            </a>
          </div>
        </article>
      }
    </div>

    <div class="row">
      @for(item of projects.slice(3, 6); track item; let index = $index) {
        <article class="col-sm-6 col-md-4 {{item.tags}}">
          <div class="box-image-4">
            <a [routerLink]="['/pages/projects/' + item.url]" [matTooltip]="item.title | translate">
              <div class="media">
                <img [src]="item.img" alt="project image" class="img-responsive">
              </div>

              <div class="body">
                <div class="content">
                  <h4 class="title">
                    {{item.title | translate}}
                  </h4>

                  <span class="category">
                    {{item.category | translate}}
                  </span>
                </div>
              </div>
            </a>
          </div>
        </article>
      }
    </div>
  </div>
</div>
