import { CommonModule } from "@angular/common";
import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-dnd-upload",
  templateUrl: "./dnd-upload.component.html",
  styleUrls: ["./dnd-upload.component.scss"],
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
    TranslateModule,
    MatTooltipModule,
    MatButtonModule,
  ],
})
export class DndUploadComponent implements OnInit {
  @Output() private selectedFile: EventEmitter<File> = new EventEmitter();
  public currentFile: File = null;

  constructor() {}

  ngOnInit() {}

  public onFileChange(file: File) {
    // if (this.fileValidation(file)) {
    //   this.currentFile = file;
    //   this.selectedFile.emit(file);
    //   return;
    // }

    // console.error("Error");

    this.currentFile = file;
    this.selectedFile.emit(file);
  }

  public removeFile() {
    this.currentFile = null;
    this.selectedFile.emit(null);
  }

  public fileValidation(file: File) {
    const isExcelFile =
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    return isExcelFile;
  }
}
