<div class="section feature overlap">
  <div class="container">
    <div class="row">
      <div class="col-sm-4 col-md-4">
        <div class="box-icon-2">
          <div class="icon">
            <i class="fa-solid fa-star"></i>
          </div>
          <div class="body-content">
            <div class="heading">
              {{'MM_Registration_Feature1_Title' | translate}}
            </div>
            {{'MM_Registration_Feature1_Description' | translate}}
          </div>
        </div>
      </div>

      <div class="col-sm-4 col-md-4">
        <div class="box-icon-2">
          <div class="icon">
            <div class="fa fa-users"></div>
          </div>
          <div class="body-content">
            <div class="heading">
              {{'MM_Registration_Feature2_Title' | translate}}
            </div>

            {{'MM_Registration_Feature2_Description' | translate}}
          </div>
        </div>
      </div>

      <div class="col-sm-4 col-md-4">
        <div class="box-icon-2">
          <div class="icon">
            <i class="fa-solid fa-chess"></i>
          </div>
          <div class="body-content">
            <div class="heading">
              {{'MM_Registration_Feature3_Title' | translate}}
            </div>

            {{'MM_Registration_Feature3_Description' | translate}}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-12">
        <h2 class="section-heading">
          {{'MM_Registration_AboutUs' | translate}}
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-5 col-md-5">
        <div class="jumbo-heading">
          <h2 class="mt-0">
            {{'MM_Registration_AboutUs_Subtitle' | translate}}
          </h2>

          <span class="fa-solid fa-building-shield"></span>
        </div>
      </div>
      <div class="col-sm-7 col-md-7">
        <p class="lead text-justify">
          <span [innerHTML]="'MM_Registration_AboutUs_Description' | translate"></span>
        </p>
      </div>
    </div>
  </div>
</div>
