<app-header [hideElements]="{ register: true }"></app-header>

<section class="container-fluid" style="margin-top: 9rem;">
  <div class="row justify-content-center">
    <div class="col-lg-8">
      <div class="section-title desktop-center padding-bottom-40">
        <h3 class="title">
          {{company?.name}} ({{company?.vat}})
        </h3>

        <p class="text-justify">
          <span [innerHTML]="'MM_Registration_ContractSigned_Text' | translate"></span>
        </p>

        <div class="btn-wrapper padding-top-30" *ngIf="company?.contract">
          <a href="javascript:void(0)" (click)="displayPDF()" class="boxed-btn btn-business col-12">
            <span>{{'General_DisplayContract' | translate}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
