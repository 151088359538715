import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ICardboardService } from "src/app/services/cardboard/cardboard.interface";
import { HomeInfoboxComponent } from "../home-infobox/home-infobox.component";

@Component({
  selector: "app-home-footer",
  templateUrl: "./home-footer.component.html",
  styleUrls: ["./home-footer.component.css"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MatTooltipModule,
    HomeInfoboxComponent,
  ],
})
export class HomeFooterComponent implements OnInit {
  public serviceList: any[];

  constructor(private cardboardService: ICardboardService) {}

  ngOnInit(): void {
    this.getServices();
  }

  getServices(): void {
    this.serviceList = this.cardboardService.getServices();
  }
}
