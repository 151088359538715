<div class="section info overlap-bottom">
  <div class="container">
    <div class="row">
      <div class="col-sm-4 col-md-4">
        <div class="box-icon-4">
          <div class="icon"><i class="fa fa-phone"></i></div>
          <div class="body-content">
            <div class="heading">
              {{'MM_Registration_CallUsNow' | translate}}
            </div>

            <span [innerHTML]="'MM_Registration_CallUsNow_Info' | translate"></span>
          </div>
        </div>
      </div>

      <div class="col-sm-4 col-md-4">
        <div class="box-icon-4">
          <div class="icon"><i class="fa fa-map-marker"></i></div>
          <div class="body-content">
            <div class="heading">
              {{'MM_Registration_ComeVisitUs' | translate}}
            </div>

            <span [innerHTML]="'MM_Registration_ComeVisitUs_Info' | translate"></span>
          </div>
        </div>
      </div>

      <div class="col-sm-4 col-md-4">
        <div class="box-icon-4">
          <div class="icon"><i class="fa fa-envelope"></i></div>
          <div class="body-content">
            <div class="heading">
              {{'MM_Registration_SendUsAMessage' | translate}}
            </div>

            <span [innerHTML]="'MM_Registration_SendUsAMessage_Info' | translate"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
