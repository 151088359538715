<div id="slides" class="section banner">
  <ul class="slides-container">
    <li *ngFor="let item of slides">
      <img src="{{item.img}}" alt="Main slide image" />
      <div class="overlay-bg"></div>

      <div class="container">
        <div class="wrap-caption">
          <h2 class="caption-heading">
            {{item.title | translate}}
          </h2>

          <p class="excerpt" >
            {{item.description | translate}}
          </p>

          <a [href]="item.learnMoreUrl" class="btn btn-secondary" [matTooltip]="item.learnMoreText | translate">
            {{item.learnMoreText | translate}}
          </a>

          <a [href]="item.contactUrl" class="btn btn-primary" [matTooltip]="item.contactText | translate">
            {{item.contactText | translate}}
          </a>
        </div>
      </div>
    </li>
  </ul>

  <nav class="slides-navigation">
    <div class="container">
      <a href="#" class="next">
        <i class="fa fa-chevron-right"></i>
      </a>

      <a href="#" class="prev">
        <i class="fa fa-chevron-left"></i>
      </a>
    </div>
  </nav>
</div>
