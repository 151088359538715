import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-home-slides",
  templateUrl: "./home-slides.component.html",
  styleUrls: ["./home-slides.component.css"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatTooltipModule],
})
export class HomeSlidesComponent implements OnInit {
  public slides: any[] = [
    {
      img: "/assets/images/1920x960_slider1.jpeg",
      title: "MM_Registration_Slide1_Title",
      description: "MM_Registration_Slide1_Description",
      learnMoreText: "MM_Registration_LearnMore",
      learnMoreUrl: "#AboutUs",
      contactText: "MM_Registration_ContactUs",
      contactUrl: "/register",
    },
    {
      img: "/assets/images/1920x960_slider2.jpg",
      title: "MM_Registration_Slide2_Title",
      description: "MM_Registration_Slide2_Description",
      learnMoreText: "MM_Registration_LearnMore",
      learnMoreUrl: "#AboutUs",
      contactText: "MM_Registration_ContactUs",
      contactUrl: "/register",
    },
    {
      img: "/assets/images/1920x960_slider3.jpg",
      title: "MM_Registration_Slide3_Title",
      description: "MM_Registration_Slide3_Description",
      learnMoreText: "MM_Registration_LearnMore",
      learnMoreUrl: "#AboutUs",
      contactText: "MM_Registration_ContactUs",
      contactUrl: "/register",
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
