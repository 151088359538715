import { Component, OnInit } from "@angular/core";
import { IComponentView } from "src/app/models/navigation/IComponentView";
import { ActivatedRoute, Router } from "@angular/router";
import { ICompanyService } from "src/app/services/company/company";
import { ViewTitleService } from "src/app/services/viewTitle/view-title.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import Swal from "sweetalert2";

@Component({
  selector: "app-company-email-validation",
  templateUrl: "./company-email-validation.component.html",
  styleUrls: ["./company-email-validation.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule, MatIconModule],
})
export class CompanyEmailValidationComponent implements OnInit {
  private componentView: IComponentView = {
    title: "Company Email Validation",
    breadCrumbsItems: [],
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private companyService: ICompanyService,
    private viewTitleService: ViewTitleService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.viewTitleService.emitChange(this.componentView);

    this.activatedRoute.queryParams.subscribe((params) => {
      const token = params["token"];
      const randomKey = params["randomKey"];
      const companyId = params["companyId"];

      if (!token || !randomKey || !companyId) {
        Swal.fire({
          title: `${this.translate.instant("General_Error")}`,
          text: `${this.translate.instant("CRM_Token_Error")}`,
          icon: "error",
        });

        return;
      }

      this.companyService
        .companyEmailValidation(token, randomKey, companyId)
        .subscribe((response: any) => {
          if (response.status === 200) {
            Swal.fire({
              title: `${this.translate.instant("General_Activated")}`,
              text: response.data,
              icon: "success",
              timer: 3000,
            }).then(() => {
              this.router.navigate(["/"]);
            });
          } else {
            Swal.fire({
              title: `${this.translate.instant("General_Error")}`,
              text: response.errors.join(","),
              icon: "error",
            }).then(() => {
              this.router.navigate(["/"]);
            });
          }
        });
    });
  }
}
