<app-header></app-header>

<div class="header-area header-business">
  <div class="business-bg-img wow animate__ animate__zoomIn animated" data-parallax='{"x": 220, "y": 100}'
    style="background-image:url('/assets/images/illustrations/email_bad.svg');">
  </div>

  <div class="business-bg-img-02 wow animate__ animate__zoomIn animated"
    style="background-image: url('/assets/images/background/bg_header.png'); visibility: visible; animation-name: zoomIn;">
  </div>

  <div class="shape-02"></div>

  <div class="container">
    <div class="row">
      <div class="col-lg-7">
        <div class="header-inner">
          <h1 class="title">
            {{'Cardboard_UnsubscribeEmail' | translate}}
          </h1>

          <p>
            {{'Cardboard_UnsubscribeText' | translate}}
          </p>

          <div class="btn-wrapper padding-top-30">
            <a href="javascript:void(0)" (click)="unsubscribeToEmails()" class="boxed-btn btn-business">
              <span *ngIf="!unsubscribe">{{'Cardboard_Unsubscribe' | translate}}</span>
              <span *ngIf="unsubscribe">{{'Cardboard_SubscribeAgain' | translate}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
