import { CompanyEmailValidationComponent } from "./components/company-registration/company-email-validation/company-email-validation.component";
import { AddLSCCompanyComponent } from "./components/company-registration/LCS-registration/add-company/add-company.component";
import { CompanyDocumentUploadComponent } from "./components/company-document-upload/company-document-upload.component";
import { EmailUnsubscribeComponent } from "./components/pages/email-unsubscribe/email-unsubscribe.component";
import { CompanyCreatedComponent } from "./components/company-created/company-created.component";
import { LandingPageComponent } from "./components/home/landing-page/landing-page.component";
import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { RepresentativeSignatureComponent } from "./components/signature/representative-signature/representative-signature.component";
import { DisplayContractSignedComponent } from "./components/pages/display-contract-signed/display-contract-signed.component";
import { RegisterPaymentComponent } from "./components/pages/register-payment/register-payment.component";
import { NotFoundComponent } from "./components/pages/not-found/not-found.component";

const routes: Routes = [
  { path: "", component: LandingPageComponent },

  {
    path: "pages",
    loadChildren: () =>
      import("./modules/pages/pages.module").then((m) => m.PagesModule),
  },

  {
    path: "register",
    loadChildren: () =>
      import("./modules/register/register.module").then((m) => m.RegisterModule),
  },

  // { path: "register-2/:id", component: AddCompanyComponent },
  // { path: "company", component: AddCompanyComponent },
  { path: "company-validation", component: CompanyEmailValidationComponent },
  { path: "company-documents", component: CompanyDocumentUploadComponent },
  { path: "company-created/:id", component: CompanyCreatedComponent },
  // { path: "lsc/add-company", component: AddLSCCompanyComponent },

  {
    path: "email-unsubscribe/:identifier/:email",
    component: EmailUnsubscribeComponent,
  },
  {
    path: "signature/:id/:uniqueCode/:contractType",
    component: RepresentativeSignatureComponent,
  },
  { path: "contract/:vat", component: DisplayContractSignedComponent },
  { path: "registrar-pago/:vat", component: RegisterPaymentComponent },

  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
