<section class="section statistic bgsection">
  <div class="container">
    <div class="col-12">
      <div class="row align-items-center"> <!-- Added align-items-center for vertical alignment -->
        <div class="counter-1 centered-container">
          <h3 class="counter-number-2" style="position: relative; color: #fff">
            {{'MM_MoreThan' | translate}}
          </h3>
        </div>
      </div>

      <div class="row statistics-row"> <!-- Added custom class statistics-row -->
        <article class="col-sm-4 col-md-4 centered-article " *ngFor="let item of statistics">
          <div class="counter-1 content">
            <div class="counter-number">
              {{item.counterNumber | translate}}
            </div>

            <div class="counter-title content">
              <span [innerHTML]="item.description | translate"></span>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</section>
