<div class="upload mb-2" (click)="file.click()" appDnd (fileChangeEmiter)="onFileChange($event.target.files)">
  <mat-icon class="col-12" style="height: 62px; width: 62px; font-size: 62px">file_upload</mat-icon>
  <br>
  <input class="col-12" type="file" multiple #file (change)="onFileChange($event.target.files)" style="display:none;" />

  <br />

  {{message | translate}}
</div>

@if(uploadedFiles.length > 0) {
<div class="mt-3">
  <table style="overflow-x: auto;" class="col-12" mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>
        {{'General_Name' | translate}}
      </th>

      <td mat-cell *matCellDef="let element">
        {{element.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let element; let i = index" style="width: 70px">
        <button type="button" mat-icon-button color="warn" (click)="removeFile(i)">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
}
