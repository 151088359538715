import { HomeFooterComponent } from "./components/ui/home-footer/home-footer.component";
import { KeyboardShortcutsModule, ShortcutInput } from "ng-keyboard-shortcuts";
import { HeaderComponent } from "./components/ui/menu/header/header.component";
import { NgxTranslateDebugService } from "ngx-translate-debug";
import { TranslateModule } from "@ngx-translate/core";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { Component } from "@angular/core";
import { HomeInfoboxComponent } from "./components/ui/home-infobox/home-infobox.component";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgxUiLoaderModule,
    KeyboardShortcutsModule,
    HeaderComponent,
    RouterModule,
    HomeFooterComponent,
    HomeInfoboxComponent
  ]
})
export class AppComponent {
  public shortcuts: ShortcutInput[] = [];
  public secretAudio = new Audio();

  constructor(private translateDebugService: NgxTranslateDebugService) {
    this.shortcuts.push({
      key: "ctrl + shift + l",
      preventDefault: true,
      command: (e) => this.toggleTranslation(),
    });

    this.secretAudio.src = "/assets/notifications/secret.mp3";
  }

  toggleTranslation(): void {
    this.secretAudio.load();
    this.secretAudio.play();

    this.translateDebugService.toggleDebug();
  }
}
