<app-home-infobox></app-home-infobox>

<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-md-6">
        <div class="footer-item">
          <img src="/assets/images/footer-logo.png" alt="logo bottom" class="logo-bottom">
          <p [innerHTML]="'General_Footer_CDC_CR' | translate"></p>

          <div class="footer-sosmed">
            <a href="https://carteldamageclaims.com/" title="" class="me-2">
              <div class="item">
                <i class="fa-solid fa-store"></i>
              </div>
            </a>

            <a href="https://x.com/C_D_C_Brussels" title="" class="me-2">
              <div class="item">
                <i class="fa-brands fa-x-twitter"></i>
              </div>
            </a>

            <a href="https://www.linkedin.com/company/cartel-damage-claims-cdc-?originalSubdomain=be" title=""
              class="me-2">
              <div class="item">
                <i class="fa-brands fa-linkedin-in"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="col-sm-3 col-md-3">
        <div class="footer-item">
          <div class="footer-title">
            Recent Post
          </div>
          <ul class="recent-post">
            <li><a href="#" title="">The Best in dolor sit amet consectetur adipisicing elit sed</a>
              <span class="date"><i class="fa fa-clock-o"></i> June 16, 2017</span>
            </li>
            <li><a href="#" title="">The Best in dolor sit amet consectetur adipisicing elit sed</a>
              <span class="date"><i class="fa fa-clock-o"></i> June 16, 2017</span>
            </li>
          </ul>
        </div>
      </div> -->
      <div class="col-sm-6 col-md-6">
        <div class="footer-item">
          <div class="footer-title">
            {{'General_OurServices' | translate}}
          </div>

          <ul class="list">
            <li *ngFor="let item of serviceList">
              <a [routerLink]="['/pages/services/' + item.id]" [matTooltip]="item.title | translate">
                {{item.title | translate}}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="col-sm-3 col-md-3">
        <div class="footer-item">
          <div class="footer-title">
            Subscribe
          </div>
          <p>Lit sed The Best in dolor sit amet consectetur adipisicing elit sedconsectetur adipisicing</p>
          <form action="#" class="footer-subscribe">
            <input type="email" name="EMAIL" class="form-control" placeholder="enter your email">
            <input id="p_submit" type="submit" value="send">
            <label for="p_submit"><i class="fa fa-envelope"></i></label>
            <p>Get latest updates and offers.</p>
          </form>
        </div>
      </div> -->
    </div>
  </div>

  <div class="fcopy">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <p class="ftex">
            {{'General_Footer_CDC_CR' | translate}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
