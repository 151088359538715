import { ICardboardService } from "src/app/services/cardboard/cardboard.interface";
import { TranslateModule } from "@ngx-translate/core";
import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

@Component({
  selector: "app-home-services",
  templateUrl: "./home-services.component.html",
  styleUrls: ["./home-services.component.css"],
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule],
})
export class HomeServicesComponent implements OnInit {
  public services: any[];

  constructor(private cardboardService: ICardboardService) {}

  ngOnInit(): void {
    this.getServices();
  }

  getServices(): void {
    this.services = this.cardboardService.getServices();
  }
}
