import { ICardboardService } from "src/app/services/cardboard/cardboard.interface";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { HeaderComponent } from "../../ui/menu/header/header.component";
import { CommonModule } from "@angular/common";
import { SmartecFileUploadComponent } from "../../addons/smartec-file-upload/smartec-file-upload.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { RepresentativeHasSignedComponent } from "../representative-has-signed/representative-has-signed.component";

@Component({
  selector: "app-representative-signature",
  templateUrl: "./representative-signature.component.html",
  styleUrls: ["./representative-signature.component.scss"],
  standalone: true,
  imports: [
    HeaderComponent,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    SmartecFileUploadComponent,
    MatButtonModule,
    MatIconModule,
    RepresentativeHasSignedComponent,
  ],
})
export class RepresentativeSignatureComponent implements OnInit {
  public company: any;
  public formGroup: FormGroup;
  public file: any;

  constructor(
    private cardboardService: ICardboardService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.formGroup = this.formBuilder.group({
      id: 0,
      uniqueCode: ["", Validators.required],
      contractType: [""],
    });

    this.activatedRoute.paramMap.subscribe((parameters: any) => {
      if (parameters?.params) {
        this.formGroup.patchValue({
          id: parameters.params.id,
          uniqueCode: parameters.params.uniqueCode,
          contractType: parameters.params.contractType,
        });

        this.getCompany(
          this.formGroup.value.id,
          this.formGroup.value.uniqueCode,
          this.formGroup.value.contractType
        );
      }
    });
  }

  getCompany(id: number, uniqueCode: string, contractType: string): void {
    this.cardboardService
      .getCardboardCompanySignature(id, uniqueCode, contractType)
      .subscribe((response: any) => {
        if (!response) {
          Swal.fire({
            title: this.translateService.instant("General_CompanyNotFound"),
            text: this.translateService.instant(
              "General_CompanyNotFoundMessage"
            ),
            icon: "info",
          });

          this.cardboardService
            .saveSystemLogs({
              type: 2,
              message: `A user was trying to obtain a signature but the company was not found, the request was performed at ${new Date()}`,
              code: "NotFound",
              data: `${JSON.stringify(this.formGroup.value)}`,
              url: `${window.location.href}`,
            })
            .subscribe((response: any) => {
              if (response) {
                console.log("🔔 A new notification was saved into our system");
              }
            });

          this.router.navigate([""]);

          return;
        }

        this.company = response;
        this.company.fileName = this.company.file?.split("/").pop();
      });
  }

  ngOnInit(): void {}

  downloadFile(): void {
    if (!this.company?.fileName) {
      return;
    }

    window.open(`${environment.cardboardURL}${this.company.file}`, "_blank");
  }

  getUploadedFiles(files: any): void {
    if (!files) {
      return;
    }

    this.file = files[0]?.file;
  }

  uploadContract(): void {
    const form = Object.assign({}, this.formGroup.value);
    this.cardboardService
      .uploadSignedContract(form, this.file)
      .subscribe((response: any) => {
        if (response) {
          Swal.fire({
            title: this.translateService.instant(
              "Cardboard_RepresentativeSignatureSuccessfulTitle"
            ),
            text: this.translateService.instant(
              "Cardboard_RepresentativeSignatureSuccessfulText"
            ),
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        }
      });
  }
}
