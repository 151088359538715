<ngx-ui-loader [fgsType]="'pulse'" [fgsColor]="'#137eff'" [overlayColor]="'rgb(18 36 58 / 80%)'" [pbColor]="'#137eff'">
</ngx-ui-loader>

<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<app-header></app-header>

<!-- <div class="animationload">
  <div class="loader"></div>
</div> -->

<a href="#0" class="cd-top cd-is-visible cd-fade-out">
  {{'General_Top' | translate}}
</a>

<router-outlet></router-outlet>

<app-home-footer></app-home-footer>
