import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-home-call-to-action",
  templateUrl: "./home-call-to-action.component.html",
  styleUrls: ["./home-call-to-action.component.css"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatTooltipModule, RouterModule],
})
export class HomeCallToActionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
