<div class="section testimony bglight">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <h2 class="section-heading">
              {{'Cars_TestimonialsTitle' | translate}}
            </h2>
          </div>
        </div>

        <div id="owl-testimony">
          <div class="item">
            <div class="testimonial-1">
              <div class="media"><img src="/assets/images/r1.jpg" alt="" class="img-responsive"></div>
              <div class="body">
                <div class="title">Michael Von den Benken</div>
                <div class="company">Director at DUNI AB, Sweden</div>
                <p>CDC’s collective approach of acquiring claims on a pan-European level and bundling them into one damage action was particularly interesting from our viewpoint as this enabled access to market-wide data and considerably increased negotiating power against the cartelists.</p>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="testimonial-1">
              <div class="media"><img src="/assets/images/r2.jpg" alt="" class="img-responsive"></div>
              <div class="body">
                <div class="title">Teemu Taxell</div>
                <div class="company">Partner at Merilampi Law Firm, Finland</div>
                <p>In general, pursuing cartel damages actions with a desired result has proven to be quite challenging, and often also burdensome and costly. From an external counsel’s perspective, CDC’s business model has provided the most effective solution for these hindrances.
                </p>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="testimonial-1">
              <div class="media"><img src="/assets/images/r3.jpg" alt="" class="img-responsive"></div>
              <div class="body">
                <div class="title">José Moutas</div>
                <div class="company">Chief Legal Counsel at ENCE Energia y Celulosa SA, Spain</div>
                <p>Having successfully achieved several significant settlements on our behalf against two different cartels CDC has shown its capability and dependability in ensuring that companies damaged by anticompetitive practices are properly compensated for their loss.
                </p>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="testimonial-1">
              <div class="media"><img src="/assets/images/r4.jpg" alt="" class="img-responsive"></div>
              <div class="body">
                <div class="title">Jan Geier</div>
                <div class="company">Steinbeis Papier GmbH, Germany</div>
                <p>CDC has won five settlements for us in two court cases in Germany and Finland, proving on several occasions that it is competent in managing complicated proceedings……Furthermore, we did not have to worry about any burden from the costs and risks associated with enforcing the claims, as these were also borne entirely by CDC.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
