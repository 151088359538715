import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ICompanyService } from "src/app/services/company/company";
import { ICompany } from "src/app/models/company/ICompany";
import { FormGroup, FormBuilder } from "@angular/forms";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { FileUploadService } from "src/app/services/file-upload/file-upload.service";
import { IFileUploadService } from "src/app/services/file-upload/file-upload";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { CommonModule } from "@angular/common";
import { FileUploadComponent } from "../addons/file-upload/file-upload.component";

@Component({
  selector: "app-company-document-upload",
  templateUrl: "./company-document-upload.component.html",
  styleUrls: ["./company-document-upload.component.scss"],
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    CommonModule,
    TranslateModule,
    FileUploadComponent,
  ],
})
export class CompanyDocumentUploadComponent implements OnInit {
  public companyFormGroup: FormGroup;
  public company: ICompany;
  public files: any;
  public showHeaders = false;
  public companyId: string;
  private router: Router;
  public uploadedFilesCount: number;
  private companyDocuments: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private companyService: ICompanyService,
    private formBuilder: FormBuilder,
    private fileUploadService: IFileUploadService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (!params) {
        return;
      }

      this.showHeaders = false;
      this.company = null;
      this.files = [];
      this.formGroupCreation();

      this.companyId = params.id ? params.id : "";
      if (this.companyId) {
        this.companyService
          .getSimpleCompanyById(this.companyId)
          .subscribe((response: any) => {
            this.showHeaders = true;

            this.company = response.company;
            this.files = response.files;

            this.companyFormGroup.patchValue({
              UpdateId: this.companyId,
              FormTypeSelection: response.company.formTypeSelection,
            });
          });
      }
    });
  }

  formGroupCreation() {
    this.companyFormGroup = this.formBuilder.group({
      UpdateId: "",
      FormTypeSelection: "",
      Documents: this.formBuilder.array([]),
    });
  }

  completeUpload() {
    if (
      this.uploadedFilesCount == undefined &&
      (this.files == 0 || this.files.length == 0)
    ) {
      Swal.fire({
        title: `${this.translate.instant("General_WarningTitle")}`,
        text: `${this.translate.instant(
          "General_NoDocumentsUploadedValidation"
        )}`,
        icon: "warning",
      });
    }
    if (
      this.uploadedFilesCount > 0 &&
      (this.files == 0 || this.files.length == 0)
    ) {
      //TODO:Add a list of unassigned documents.
      this.companyDocuments = Object.assign({}, this.companyFormGroup.value);
      const unassignedDocuments = this.companyDocuments.Documents;
      this.fileUploadService
        .uploadUnassignedDocuments(unassignedDocuments)
        .subscribe((response: any) => {
          Swal.fire({
            title: `${this.translate.instant("General_Created")}`,
            text: `${this.translate.instant(
              "General_ContractDocumentMessageSuccess"
            )}`,
            icon: "success",
            timer: 6000,
          }).then(() => {
            this.router.navigate(["/"]);
          });
        });
    } else if (
      this.uploadedFilesCount == undefined ||
      this.uploadedFilesCount == 0
    ) {
      Swal.fire({
        title: `${this.translate.instant("General_MissingDocuments")}`,
        text: `${this.translate.instant(
          "General_UploadSignedDocumentsWarning"
        )}`,
        icon: "error",
      });
    } else if (this.uploadedFilesCount < this.files.length) {
      Swal.fire({
        title: `${this.translate.instant("General_MissingDocuments")}`,
        text: `${this.translate.instant(
          "General_MissingDocumentUploadValidation"
        )}`,
        icon: "warning",
      });
    } else if (this.uploadedFilesCount == this.files.length) {
      //upload documents to the companyList
      if (this.companyId != undefined && this.companyId != null) {
        this.companyDocuments = Object.assign({}, this.companyFormGroup.value);
        this.companyService
          .updateDocumentsCompany(this.companyDocuments)
          .subscribe((response: any) => {
            Swal.fire({
              title: `${this.translate.instant("General_Created")}`,
              text: `${this.translate.instant(
                "General_ContractDocumentMessageSuccess"
              )}`,
              icon: "success",
              timer: 6000,
            }).then(() => {
              this.router.navigate(["/"]);
            });
          });
      } else {
        //TODO:Add a list of unassigned documents.
        const unassignedDocuments = this.companyDocuments.Documents;
        this.fileUploadService
          .uploadUnassignedDocuments(unassignedDocuments)
          .subscribe((response: any) => {
            Swal.fire({
              title: `${this.translate.instant("General_Created")}`,
              text: `${this.translate.instant(
                "General_ContractDocumentMessageSuccess"
              )}`,
              icon: "success",
              timer: 6000,
            }).then(() => {
              this.router.navigate(["/"]);
            });
          });
      }
    }
    return;
  }
  updateDocumentsCount(event) {
    this.uploadedFilesCount = event;
  }
}
