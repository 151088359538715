<div class="container">
  <div class="row">
    <div class="col">
      <div class="alert alert-success" role="alert">
        <b>
          <mat-icon>refresh</mat-icon> Activating company...
        </b>
      </div>
    </div>
  </div>
</div>
