<div class="section cta">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <div class="cta-info">
          <h3>
            {{'MM_Registration_CallToActionTitle' | translate}}
          </h3>

          <p [innerHTML]="'MM_Registration_CallToActionDescription' | translate"></p>

          <a [routerLink]="['/register']" matTooltip="{{'MM_Registration_RegisterNow' | translate}}" class="btn btn-cta">
            {{'MM_Registration_RegisterNow' | translate}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
