import { ITranslationService } from "src/app/services/translations/translation";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Component, Input, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class HeaderComponent implements OnInit {
  private _hideElements: any;

  @Input() set hideElements(value: any) {
    this._hideElements = value;
  }

  get hideElements(): any {
    return this._hideElements;
  }

  public selectedLanguage: any = {
    name: "Spanish",
    value: "es",
    flag: "es",
  };

  public includedLanguages = ["en", "es"];
  public listLang: any = [];

  constructor(
    public translate: TranslateService,
    public translationService: ITranslationService,
    public title: Title
  ) {
    this.translate
      .stream("MM_Registration_CartelPageTitle")
      .subscribe((value: string) => {
        this.title.setTitle(`${value} | CDC | SCALE`);
      });
  }

  setLanguageLocalStorage(): void {
    let language = localStorage.getItem("mm_landing_page");

    if (!language) {
      localStorage.setItem("mm_landing_page", "es");
      language = "es";
    }

    this.translate.setDefaultLang(language);
    this.translate.use(language);

    const selectedLanguage = this.listLang.filter(
      (lang: any) => lang.value === language
    )[0];
    if (selectedLanguage) {
      this.selectedLanguage = selectedLanguage;
    }
  }

  ngOnInit(): void {
    this.getLanguages();
  }

  getLanguages() {
    this.translationService.getLanguagesList().subscribe((response: any) => {
      const filter = response.filter((lang: any) =>
        this.includedLanguages.includes(lang.value)
      );

      this.listLang = filter.sort((a: any, b: any) => a.name - b.name);

      if (this.listLang) {
        this.listLang = this.listLang.map((language: any) => {
          if (language.value == "es") {
            language.name = "Spanish";
          }

          language.name = "General_" + language.name.trim();

          return language;
        });
      }

      this.setLanguageLocalStorage();
    });
  }

  selectLanguage(lang: any): void {
    this.selectedLanguage = lang;
    this.translationService.onLanguageChange(lang.value);
    this.translate.use(lang.value);

    localStorage.setItem("mm_landing_page", lang.value);
  }
}
