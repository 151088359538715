<div class="header">
  <div class="topbar">
    <div class="container">
      <div class="row">
        <div class="col-sm-8">
          <div class="topbar-left">
            <div class="welcome-text">
              {{'MM_Registration_MainHeaderBlueLineText' | translate}}
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="topbar-right">
            <ul class="topbar-menu">
              <li>
                <a href="javascript:void(0)" [matMenuTriggerFor]="menu">
                  <span
                    class="me-1 flag-icon flag-icon-{{selectedLanguage.flag == 'en' ? 'gb' : selectedLanguage.flag}}"></span>
                  {{selectedLanguage.name | translate}}
                </a>

                <mat-menu #menu="matMenu">
                  @for(lang of listLang; track lang) {
                  <button type="button" mat-menu-item (click)="selectLanguage(lang)">
                    <span class="me-1 flag-icon flag-icon-{{lang.flag == 'en' ? 'gb' : lang.flag}}"></span> {{lang.name
                    | translate}}
                  </button>
                  }
                </mat-menu>
              </li>
              <li>
                <a [routerLink]="['/register']">
                  {{'MM_Registration_RegisterNow' | translate}}
                </a>
              </li>
            </ul>

            <ul class="topbar-sosmed">
              <li>
                <a href="https://carteldamageclaims.com/" target="_blank">
                  <i class="fa-solid fa-store"></i>
                </a>
              </li>
              <li>
                <a href="https://x.com/C_D_C_Brussels" target="_blank">
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/cartel-damage-claims-cdc-?originalSubdomain=be"
                  target="_blank">
                  <i class="fa-brands fa-linkedin-in"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="topbar-logo">
    <div class="container">
      <div class="contact-info">
        <div class="box-icon-1">
          <div class="icon">
            <i class="fa-solid fa-envelope-open-text"></i>
          </div>

          <div class="body-content">
            <div class="heading">
              {{'MM_Registration_EmailSupport' | translate}}
            </div>

            {{'MM_Registration_ContactEmail' | translate}}
          </div>
        </div>

        <div class="box-icon-1">
          <div class="icon">
            <div class="fa fa-phone"></div>
          </div>
          <div class="body-content">
            <div class="heading">
              {{'MM_Registration_CallSupport' | translate}}
            </div>

            {{'MM_Registration_ContactPhone' | translate}}
          </div>
        </div>

        <a [routerLink]="['/register']" class="btn btn-cta pull-right">
          {{'MM_Registration_RegisterNow' | translate}}
        </a>
      </div>
    </div>
  </div>

  <!-- NAVBAR SECTION -->
  <div class="navbar navbar-main">

    <div class="container container-nav">
      <div class="rowe">

        <div class="navbar-header">
          <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>

        </div>

        <a href="/" class="navbar-brand">
          <img src="/assets/images/cdc-logo.png" alt="Logo" style="max-width: 74%;" />
        </a>

        <!-- <app-menu></app-menu> -->
      </div>
    </div>
  </div>
</div>
