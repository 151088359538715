import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  Input,
} from "@angular/core";
import { IFileUploadService } from "src/app/services/file-upload/file-upload";
import { FileUploadItem } from "src/app/models/files/fileUploadItem";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { FormArray, FormControl } from "@angular/forms";
import { MatSort } from "@angular/material/sort";
import * as moment from "moment";
import { concat } from "rxjs";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    MatTableModule,
    MatPaginatorModule,
  ],
})
export class FileUploadComponent implements OnInit {
  @Output() private selectedFile: EventEmitter<File> = new EventEmitter();
  @ViewChild(MatPaginator, { static: true, read: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true, read: true }) sort: MatSort;

  @Input() companyId = "";
  @Input() documents: FormArray;
  @Input() docLimit = 0;
  @Input() documentType: string = "non-specified";
  @Output() documentsUploadChage = new EventEmitter();

  public listFilesUpload: File[] = []; //Could be deleted
  public listUploadedDocument: FileUploadItem[] = [];
  public currentFile: File = null;
  public dataSource: MatTableDataSource<string>;
  public displayedColumns: string[] = ["Name", "Progress", "Actions"];

  constructor(private _uploadService: IFileUploadService) {}

  ngOnInit() {}

  public onFileChange(files: File[]) {
    this.listFilesUpload = files;

    if (
      this.docLimit > 0 &&
      this.listUploadedDocument.length >= this.docLimit
    ) {
      this.clearLocalDocumentsList();
      this.documents.reset([]);
      this.documents.clear();
    }

    for (let i = 0; i < files.length; i++) {
      const currentFile = this.listFilesUpload[i];

      if (this.fileValidation(currentFile)) {
        const isNameInList =
          this.listUploadedDocument.filter(
            (file) => file.Name === currentFile.name
          ).length > 0;

        if (!isNameInList) {
          const currentDateName = this.generateFileName(currentFile.name);
          this.listUploadedDocument.push(
            new FileUploadItem(
              currentFile.name,
              currentDateName,
              currentFile.type,
              currentFile.size.toString(),
              files[i]
            )
          );
          this.documents.push(
            new FormControl({
              name: currentFile.name,
              type: this.documentType,
              datetimeName: currentDateName,
              mimeType: currentFile.type,
              extension: currentFile.name.split(".").pop(),
              size: currentFile.size,
            })
          );
        }
      } else {
        console.error("Error");
        return;
      }
    }

    this.updateDocumentList();
    this.uploadDocuments();
    this.documentsUploadChaged();
  }

  public generateFileName(name: string): string {
    return `${moment().format("YYYY_MM_DD_HH_mm_ss")}_${name}`;
  }

  public fileValidation(file: File) {
    // // return file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    return true;
  }

  public updateDocumentList(): void {
    const listFileName = this.listUploadedDocument.map((file) => file.Name);
    this.dataSource = new MatTableDataSource(listFileName);
    this.dataSource.paginator = this.paginator;
  }

  public removeFile(index: number): void {
    this.listUploadedDocument.splice(index, 1);
    this.updateDocumentList();
  }

  public uploadDocuments(): void {
    const resquestListResult = [];
    const documentUploadQueue = this.listUploadedDocument.map(
      (fileToUpload) => {
        return this._uploadService.uploadFiles(fileToUpload, this.companyId);
      }
    );

    const observableList = concat(...documentUploadQueue);

    observableList.subscribe((results) => {
      if (typeof results === "object") {
        resquestListResult.push(results);
      }

      if (resquestListResult.length === documentUploadQueue.length) {
        const errorList = resquestListResult.filter(
          (response) => response.Status === 500
        );
      }
    });
  }

  public documentsUploadChaged() {
    this.documentsUploadChage.emit(this.documents.length);
  }
  public clearLocalDocumentsList() {
    this.listUploadedDocument = [];
  }
}
