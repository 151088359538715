import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { RouterModule } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.css"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MatButtonModule,
  ],
})
export class NotFoundComponent implements OnInit {
  public defaultLanguage: string = "en";

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.defaultLanguage =
      this.translateService.getBrowserLang().split("-")[0] ?? "en";
  }
}
