<div id="404" class="padding-bottom-120 mt-5">
  <div class="container">
    <div class="card m-2">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-12">
            <div class="text-info mat-display-4">
              404
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-8">
            <div class="d-flex flex-row-fluid">
              <div class="d-flex flex-row-fluid flex-column justify-content-md-center p-12">
                <h1 class="mat-display-3">{{'General_404_Title' | translate}}</h1>

                <p>
                  {{'General_404_Message' | translate}}
                </p>

                <a href="/" mat-raised-button color="primary" class="m-6">
                  {{'General_404_Action' | translate}}
                </a>
              </div>
            </div>

          </div>

          <div class="col-12 col-sm-4">
            <img src="/assets/images/illustrations/error.png" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
