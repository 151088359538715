import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-home-projects",
  templateUrl: "./home-projects.component.html",
  styleUrls: ["./home-projects.component.css"],
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, MatTooltipModule],
})
export class HomeProjectsComponent implements OnInit {
  public categories: string[] = [];
  public projects: any[] = [
    {
      title: "MM_Registration_Project1_Title",
      img: "/assets/images/projects/sm/9cCeS9Sg6nU.jpg",
      category: "",
      tags: "",
      url: "key-facts-of-the-cartels",
    },
    {
      title: "MM_Registration_Project2_Title",
      img: "/assets/images/projects/sm/misAHv6YWk.jpg",
      category: "",
      tags: "",
      url: "appels-procedures",
    },
    {
      title: "MM_Registration_Project3_Title",
      img: "/assets/images/projects/sm/s8gXH459728.jpg",
      category: "",
      tags: "",
      url: "affected-markets",
    },
    {
      title: "MM_Registration_Project4_Title",
      img: "/assets/images/projects/sm/2PJMDIgK9E.png",
      category: "",
      tags: "",
      url: "cartel-members",
    },
    {
      title: "MM_Registration_Project5_Title",
      img: "/assets/images/projects/sm/UoIiVYka3VY.png",
      category: "",
      tags: "",
      url: "limitation-periods",
    },
    {
      title: "MM_Registration_Project6_Title",
      img: "/assets/images/projects/sm/PDPUPuNZ8.png",
      category: "",
      tags: "",
      url: "how-to-prepare-your-information",
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories(): void {
    if (this.projects?.length < 1) {
      return;
    }

    this.categories = this.projects
      .map((project: any) => project.tags)
      .filter((category) => category);
  }
}
