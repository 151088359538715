<div class="upload row" (click)="file.click()" *ngIf="!currentFile" appDnd (fileChangeEmiter)="onFileChange($event)">
  <mat-icon class="col-12" icon="upload" size="5x"></mat-icon>
  <input class="col-12" type="file" #file (change)="onFileChange(file.files[0])"
    accept=".pdf, .doc, .docx, .xls, .xlsx, image/jpeg, image/png, image/gif" style="display:none;" />

  <br />
  <span>
    {{'General_UploadFileMessage' | translate}}
  </span>
</div>

<section *ngIf="currentFile && currentFile?.name">
  <span class="text-primary">{{currentFile?.name}}</span> <mat-icon icon="times-circle"
    class="text-danger ms-2 cursor--pointer" matTooltip="{{'General_Delete' | translate}}"
    (click)="removeFile()"></mat-icon>
</section>
