<app-home-slides></app-home-slides>
<app-home-features></app-home-features>
<app-home-services></app-home-services>
<app-home-statistic></app-home-statistic>

<app-home-projects></app-home-projects>
<app-home-testimonials></app-home-testimonials>
<app-home-call-to-action></app-home-call-to-action>

<!-- <div class="section blog">
  <div class="container">
    <div class="row">

      <div class="col-sm-12 col-md-12">
        <h2 class="section-heading">
          RECENT NEWS
        </h2>
      </div>

      <div class="col-sm-6 col-md-3">
        <div class="box-news-1">
          <div class="media gbr">
            <img src="/assets/images/600x400.jpg" alt="" class="img-responsive">
          </div>
          <div class="body">
            <div class="title"><a href="blog-single.html" title="">The Best in dolor sit amet consectetur adipisicing
                elit sed</a></div>
            <div class="meta">
              <span class="date"><i class="fa fa-clock-o"></i> Aug 24, 2017</span>
              <span class="comments"><i class="fa fa-comment-o"></i> 0 Comments</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-3">
        <div class="box-news-1">
          <div class="media gbr">
            <img src="/assets/images/600x400.jpg" alt="" class="img-responsive">
          </div>
          <div class="body">
            <div class="title"><a href="blog-single.html" title="">The Best in dolor sit amet consectetur adipisicing
                elit sed</a></div>
            <div class="meta">
              <span class="date"><i class="fa fa-clock-o"></i> Aug 24, 2017</span>
              <span class="comments"><i class="fa fa-comment-o"></i> 0 Comments</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-3">
        <div class="box-news-1">
          <div class="media gbr">
            <img src="/assets/images/600x400.jpg" alt="" class="img-responsive">
          </div>
          <div class="body">
            <div class="title"><a href="blog-single.html" title="">The Best in dolor sit amet consectetur adipisicing
                elit sed</a></div>
            <div class="meta">
              <span class="date"><i class="fa fa-clock-o"></i> Aug 24, 2017</span>
              <span class="comments"><i class="fa fa-comment-o"></i> 0 Comments</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-3">
        <div class="box-news-1">
          <div class="media gbr">
            <img src="/assets/images/600x400.jpg" alt="" class="img-responsive">
          </div>
          <div class="body">
            <div class="title"><a href="blog-single.html" title="">The Best in dolor sit amet consectetur adipisicing
                elit sed</a></div>
            <div class="meta">
              <span class="date"><i class="fa fa-clock-o"></i> Aug 24, 2017</span>
              <span class="comments"><i class="fa fa-comment-o"></i> 0 Comments</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <app-home-clients></app-home-clients> -->

<!-- <app-home-footer></app-home-footer> -->
