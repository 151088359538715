<div class="section service section-border bglight">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <h2 class="section-heading">
          {{'MM_Registration_Services' | translate}}
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-md-4" *ngFor="let item of services">
        <div class="feature-box-8">
          <div class="media">
            <img [src]="item.sm" alt="service image" class="img-responsive">
          </div>

          <div class="body">
            <div class="icon-holder">
              <span class="icon {{item.icon}}"></span>
            </div>

            <a [routerLink]="['/pages/services/' + item.id]" class="title">
              {{item.title | translate}}
            </a>

            <p [innerHTML]="item.description | translate"></p>

            <a [routerLink]="['/pages/services/' + item.id]" class="readmore">
              {{item.iconText | translate}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
