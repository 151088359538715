import { Observable } from "rxjs";

export abstract class ICardboardService {
  abstract getCardboardCompanyByVAT(VAT: string): Observable<any>;
  abstract getCardboardCompanyBankDetailsByVAT(vat: string): Observable<any>;
  abstract getCardboardCompanyById(id: number): Observable<any>;
  abstract companyRegistration(cardboardCompany: any): Observable<any>;
  abstract updateCardboardCompany(cardboardCompany: any): Observable<any>;
  abstract setRepresentatives(representatives: any): Observable<any>;
  abstract uploadDocumentToCompany(
    companyId: number,
    documentType: string,
    file: any
  ): Observable<any>;
  abstract createCardboardNDA(data: any): Observable<any>;
  abstract getCardboardUnsubscribeByEmail(email: string): Observable<any>;
  abstract unsubscribeToEmails(email: string): Observable<any>;
  abstract deleteUser(user: any): Observable<any>;
  abstract saveSystemLogs(logs: any): Observable<any>;
  abstract getCardboardCompanySignature(
    id: number,
    email: string,
    contractType: string
  ): Observable<any>;
  abstract uploadSignedContract(form: any, file: any): Observable<any>;
  abstract getLegalFormsByCountryISO2(iso2: string): Observable<any>;
  abstract getStatesByCountry(country: string): Observable<any>;
  abstract checkDuplicatedVAT(vat: string): Observable<any>;
  abstract getCompanyInfo(vat: string): Observable<any>;
  abstract validateDNI(dni: string): Observable<any>;
  abstract saveBankInfoAccount(bankDetails: any): Observable<any>;
  abstract getBankDetailsFromIban(iban: string): Observable<any>;
  abstract getBanksByCountry(country: string): Observable<any>;
  abstract getRepresentativeTitlesByProject(projectId: string): Observable<any>;
  abstract getInvoicesByCompany(companyId: string): Observable<any>;
  abstract isApprovedInvoice(companyId: string): Observable<any>;
  abstract deleteInvoice(invoice: any): Observable<any>;
  abstract getServices(): any[];
  abstract getService(id: string): any;
  abstract getProject(id: string): any;
  abstract downloadBrochure(): Observable<Blob>;
  abstract getCompanyStatus(vat: string): Observable<any>;
}
