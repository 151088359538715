<div class="row">
  <div class="col-12">
    <div class="upload mb-2" (click)="file.click()" *ngIf="!currentFile" appDnd
      (fileChangeEmiter)="onFileChange($event)">
      <mat-icon class="col-12" icon="upload" size="3x"></mat-icon>

      <input class="col-12" type="file" multiple #file (change)="onFileChange(file.files)" style="display:none;" />

      <br />

      {{'General_DnDMessage' | translate}}
    </div>
  </div>

  <div class="col-12">
    <div class="col-12 mt-3" *ngIf="listUploadedDocument.length > 0">
      <hr />
      <!--
            <button type="button" class="btn btn-sm btn-info mb-2" (click)="uploadDocuments()"
                *ngIf="listUploadedDocument.length >= 1">
                <mat-icon icon="file-upload"></mat-icon> {{'General_UploadDocuments' | translate | titlecase}}
            </button> -->

      <table style="overflow-x: auto;" class="col-12" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef>
            {{'General_Name' | translate | titlecase}}
          </th>
          <td mat-cell *matCellDef="let element"> {{element}} </td>
        </ng-container>

        <ng-container matColumnDef="Progress">
          <th mat-header-cell *matHeaderCellDef>
            {{'General_Progress' | translate | titlecase}}
          </th>

          <td mat-cell *matCellDef="let element; let i = index">
            <div class="progress">
              <div class="progress-bar" role="progressbar" [style.width]="listUploadedDocument[i].Progress+'%'"
                aria-valuemin="0" aria-valuemax="100">{{listUploadedDocument[i].Progress}}%
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef>
            {{'General_Actions' | translate | titlecase}}
          </th>

          <td mat-cell *matCellDef="let element; let i = index">
            <button type="button" mat-icon-button color="warn" (click)="removeFile(i)">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
