import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-home-testimonials",
  templateUrl: "./home-testimonials.component.html",
  styleUrls: ["./home-testimonials.component.css"],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class HomeTestimonialsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
