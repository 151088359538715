import { ICardboardService } from "src/app/services/cardboard/cardboard.interface";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { HeaderComponent } from "../../ui/menu/header/header.component";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CommonModule } from "@angular/common";
import Swal from "sweetalert2";

@Component({
  selector: "app-email-unsubscribe",
  templateUrl: "./email-unsubscribe.component.html",
  styleUrls: ["./email-unsubscribe.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, HeaderComponent],
})
export class EmailUnsubscribeComponent implements OnInit {
  public unsubscribe: any;
  public email: string = "";

  constructor(
    private cardboardService: ICardboardService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe((value: any) => {
      this.email = value.email;

      this.cardboardService
        .getCardboardUnsubscribeByEmail(value.email)
        .subscribe((response: any) => {
          if (response?.deleted) {
            response = undefined;
          }

          this.unsubscribe = response;
        });
    });
  }

  ngOnInit(): void {}

  unsubscribeToEmails(): void {
    if (!this.email) {
      return;
    }

    this.cardboardService
      .unsubscribeToEmails(this.email)
      .subscribe((value: any) => {
        if (value) {
          Swal.fire({
            title: !this.unsubscribe
              ? this.translateService.instant("Cardboard_Unsubscribe")
              : this.translateService.instant("Cardboard_SubscribeAgain"),
            text: !this.unsubscribe
              ? this.translateService.instant(
                  "Cardboard_UnsubscribeSuccessText"
                )
              : this.translateService.instant(
                  "Cardboard_SubscribeAgainSuccessText"
                ),
            icon: "success",
          });

          if (this.unsubscribe) {
            this.unsubscribe = undefined;
          } else {
            this.unsubscribe = value;
          }
        }
      });
  }
}
