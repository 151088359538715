export const environment = {
  name: "production",
  production: true,
  crmURL: "https://crm.api.smartec.pro",
  cardboardURL: "https://mymi.api.carteldamageclaims.com",
  contractURL: "https://contractbuilder.smartec.pro",
  resourcesURL: "https://resources.crm.smartec.pro",
  SENTRY_URL: "https://sentry.smartec.pro/api",
  SENTRY_DSN: "https://3a33bb2fbc8442329922c4c776cab264@sentry.smartec.pro/3"
};
