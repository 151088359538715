import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-home-statistic",
  templateUrl: "./home-statistic.component.html",
  styleUrls: ["./home-statistic.component.css"],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class HomeStatisticComponent implements OnInit {
  public statistics: any[] = [
    {
      counterNumber: "MM_Registration_Statistic1_Counter",
      description: "MM_Registration_Statistic1_Description",
    },
    {
      counterNumber: "MM_Registration_Statistic2_Counter",
      description: "MM_Registration_Statistic2_Description",
    },
    {
      counterNumber: "MM_Registration_Statistic3_Counter",
      description: "MM_Registration_Statistic3_Description",
    },
    // {
    //   counterNumber: "MM_Registration_Statistic4_Counter",
    //   description: "MM_Registration_Statistic4_Description",
    // },
  ];

  constructor() {}

  ngOnInit(): void {}
}
