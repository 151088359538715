import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HomeSlidesComponent } from "../../ui/home-slides/home-slides.component";
import { HomeFeaturesComponent } from "../../ui/home-features/home-features.component";
import { HomeServicesComponent } from "../../ui/home-services/home-services.component";
import { HomeStatisticComponent } from "../../ui/home-statistic/home-statistic.component";
import { HomeProjectsComponent } from "../../ui/home-projects/home-projects.component";
import { HomeTestimonialsComponent } from "../../ui/home-testimonials/home-testimonials.component";
import { HomeCallToActionComponent } from "../../ui/home-call-to-action/home-call-to-action.component";
import { HomeInfoboxComponent } from "../../ui/home-infobox/home-infobox.component";
import { HomeFooterComponent } from "../../ui/home-footer/home-footer.component";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    HomeSlidesComponent,
    HomeFeaturesComponent,
    HomeServicesComponent,
    HomeStatisticComponent,
    HomeProjectsComponent,
    HomeTestimonialsComponent,
    HomeCallToActionComponent,
    HomeInfoboxComponent,
    HomeFooterComponent,
  ],
})
export class LandingPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
