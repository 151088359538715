import { TranslateModule } from "@ngx-translate/core";
import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-home-features",
  templateUrl: "./home-features.component.html",
  styleUrls: ["./home-features.component.css"],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class HomeFeaturesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
