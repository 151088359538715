import { environment } from "src/environments/environment";
import { ICardboardService } from "./cardboard.interface";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import Swal from "sweetalert2";
import { IHelperService } from "../helper/helper";
import { TranslateService } from "@ngx-translate/core";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: "root",
})
export class CardboardService implements ICardboardService {
  constructor(
    private helperService: IHelperService,
    private translate: TranslateService,
    private http: HttpClient
  ) {}

  public getCardboardCompanyByVAT(VAT: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.cardboardURL}/api/cardboard/getCardboardCompanyByVAT?VAT=${VAT}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )} \n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public getCardboardCompanyBankDetailsByVAT(vat: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.cardboardURL}/api/cardboard/getCardboardCompanyBankDetailsByVAT?VAT=${vat}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            let id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )} \n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public getCardboardCompanyById(id: number): Observable<any> {
    return this.http
      .get<any>(
        `${environment.cardboardURL}/api/cardboard/getCardboardCompanyById?id=${id}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public companyRegistration(cardboardCompany: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.cardboardURL}/api/cardboard/companyRegistration`,
        cardboardCompany
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public updateCardboardCompany(cardboardCompany: any): Observable<any> {
    return this.http
      .put<any>(
        `${environment.cardboardURL}/api/cardboard/updateCardboardCompany`,
        cardboardCompany
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public setRepresentatives(representatives: any): Observable<any> {
    return this.http
      .put<any>(
        `${environment.cardboardURL}/api/cardboard/setRepresentativesToCompany`,
        representatives
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public uploadDocumentToCompany(
    companyId: number,
    documentType: string,
    file: any
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("fileDocumentType", documentType);
    formData.append("file", file, file.name);
    formData.append("id", companyId.toString());

    return this.http
      .post<any>(
        `${environment.cardboardURL}/api/cardboard/uploadDocumentToCompany`,
        formData
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  public createCardboardNDA(data: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.cardboardURL}/api/cardboard/createCardboardNDA`,
        data
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )} \n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public getCardboardUnsubscribeByEmail(email: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.cardboardURL}/api/cardboard/getCardboardUnsubscribeByEmail?email=${email}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public unsubscribeToEmails(email: string): Observable<any> {
    return this.http
      .post<any>(
        `${environment.cardboardURL}/api/cardboard/UnsubscribeToEmails`,
        {
          email,
        }
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public deleteUser(user: any): Observable<any> {
    return this.http
      .post<any>(`${environment.cardboardURL}/api/cardboard/deleteUser`, user)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  saveSystemLogs(logs: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.cardboardURL}/api/systemLogs/saveSystemLogs`,
        logs
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          }
        })
      );
  }

  getCardboardCompanySignature(
    id: number,
    email: string,
    contractType: string
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.cardboardURL}/api/cardboard/getCardboardCompanySignature?id=${id}&email=${email}&contractType=${contractType}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            const id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );

            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  uploadSignedContract(form: any, file: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("id", form.id);
    formData.append("uniqueCode", form.uniqueCode);
    formData.append("contractType", form.contractType);
    formData.append("file", file, file.name);

    return this.http
      .post<any>(
        `${environment.cardboardURL}/api/cardboard/uploadSignedContract`,
        formData
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      );
  }

  getLegalFormsByCountryISO2(iso2: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.crmURL}/api/legalForms/getLegalFormsByCountryISO2?iso2=${iso2}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  getStatesByCountry(country: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.crmURL}/api/countries/getStatesByCountry?countryName=${country}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  checkDuplicatedVAT(vat: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.cardboardURL}/api/cardboard/checkDuplicatedVAT?vat=${vat}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  getCompanyInfo(vat: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.cardboardURL}/api/cardboard/getCompanyInfo?vat=${vat}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  validateDNI(dni: string): Observable<any> {
    return this.http
      .get<any>(`https://documentconverter.smartec.pro/validate/dni?dni=${dni}`)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  saveBankInfoAccount(bankDetails: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.cardboardURL}/api/cardboard/saveBankInfoAccount`,
        bankDetails
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            let id = Sentry.captureMessage(
              `Server request failed with ${response.status}`
            );

            Swal.fire({
              icon: "error",
              title: `${this.translate.instant(
                "General_Error"
              )}\n Trace ID: ${id}`,
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public getBankDetailsFromIban(iban: string): Observable<any> {
    return this.http
      .post<any>(`https://documentconverter.smartec.pro/iban/decode`, { iban })
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              html: `${response.errors.join(", ")}`,
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public getBanksByCountry(country: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.crmURL}/api/companies/getAllBanksByCountry?country=${country}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.errors[0]?.replace(
                "One or more errors occurred.",
                ""
              ),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  getRepresentativeTitlesByProject(projectId: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.crmURL}/api/projects/${projectId}/representativeTitles`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  getInvoicesByCompany(companyId: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.crmURL}/api/invoicePayments/search/${companyId}/project/63aabe251e00c4ecdb9da718`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  isApprovedInvoice(companyId: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.crmURL}/api/invoicePayments/is-approved/${companyId}/project/63aabe251e00c4ecdb9da718`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  deleteInvoice(invoice: any): Observable<any> {
    return this.http
      .post<any>(`${environment.crmURL}/api/invoicePayments/delete-invoice`, {
        invoiceId: invoice.id,
        companyId: invoice.cardboardCompanyId.toString(),
        reason: invoice.reason,
      })
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  getServices(): any[] {
    return [
      {
        id: "quick-facts",
        img: "/assets/images/services/2760241.jpg",
        sm: "/assets/images/services/sm/2760241.jpg",
        icon: "fa-solid fa-circle-info",
        title: "MM_Registration_Service2_Title",
        description: "MM_Registration_Service2_Description",
        iconText: "MM_Registration_ReadMore",

      },
      {
        id: "effectiveness-of-our-approach",
        img: "/assets/images/services/14232132965260.jpg",
        sm: "/assets/images/services/sm/14232132965260.jpg",
        icon: "fa-solid fa-briefcase",
        title: "MM_Registration_Service3_Title",
        description: "MM_Registration_Service3_Description",
        iconText: "MM_Registration_ReadMore",
      },
      {
        id: "how-does-our-model-work",
        img: "/assets/images/services/256297.jpg",
        sm: "/assets/images/services/sm/256297.jpg",
        icon: "fa-solid fa-clipboard-list",
        title: "MM_Registration_Service4_Title",
        description: "MM_Registration_Service4_Description",
        iconText: "MM_Registration_ReadMore",
      },
      {
        id: "unique-expertise-in-europe",
        img: "/assets/images/services/29PaIGCEq10.jpg",
        sm: "/assets/images/services/sm/29PaIGCEq10.jpg",
        icon: "fa-solid fa-scale-balanced",
        title: "MM_Registration_Service5_Title",
        description: "MM_Registration_Service5_Description",
        iconText: "MM_Registration_ReadMore",
      },
      {
        id: "key-advantages-for-damaged-companies",
        img: "/assets/images/services/mhUsz2ezlXQ.jpg",
        sm: "/assets/images/services/sm/mhUsz2ezlXQ.jpg",
        icon: "fa-solid fa-newspaper",
        title: "MM_Registration_Service6_Title",
        description: "MM_Registration_Service6_Description",
        iconText: "MM_Registration_ReadMore"
      },
      {
        id: "next-steps",
        img: "/assets/images/services/600x400_1.jpeg",
        sm: "/assets/images/services/600x400_1.jpeg",
        icon: "fa-solid fa-shoe-prints",
        title: "MM_Registration_Service1_Title",
        description: "MM_Registration_Service1_Description",
        iconText: "MM_Registration_ReadMore",
      },
    ];
  }

  getService(id: string): any {
    switch (id) {
      case "quick-facts":
        return {
          img: "/assets/images/services/2760241.jpg",
          title: "MM_Registration_Service2_Title",
          subTitle: "MM_Registration_Service2_SubTitle_Page",
          description: "MM_Registration_Service2_Description_Page",
          blueSection:
            "MM_Registration_Service2_BusinessPlanMarket_BlueSection_Page",
          whatWeDoTitle: "MM_Registration_Service2_WhatWeDo_Title_Page",
          whatWeDoDescription:
            "MM_Registration_Service2_WhatWeDo_Description_Page",
          businessPlanMarketTitle:
            "MM_Registration_Service2_BusinessPlanMarket_Title_Page",
          businessPlanMarketDescription:
            "MM_Registration_Service2_BusinessPlanMarket_Description_Page",
        };

      case "effectiveness-of-our-approach":
        return {
          img: "/assets/images/services/14232132965260.jpg",
          title: "MM_Registration_Service3_Title",
          subTitle: "MM_Registration_Service3_SubTitle_Page",
          description: "MM_Registration_Service3_Description_Page",
          blueSection:
            "MM_Registration_Service3_BusinessPlanMarket_BlueSection_Page",
          whatWeDoTitle: "MM_Registration_Service3_WhatWeDo_Title_Page",
          whatWeDoDescription:
            "MM_Registration_Service3_WhatWeDo_Description_Page",
          businessPlanMarketTitle:
            "MM_Registration_Service3_BusinessPlanMarket_Title_Page",
          businessPlanMarketDescription:
            "MM_Registration_Service3_BusinessPlanMarket_Description_Page",
        };

      case "how-does-our-model-work":
        return {
          img: "/assets/images/services/256297.jpg",
          title: "MM_Registration_Service4_Title",
          subTitle: "MM_Registration_Service4_SubTitle_Page",
          description: "MM_Registration_Service4_Description_Page",
          blueSection:
            "MM_Registration_Service4_BusinessPlanMarket_BlueSection_Page",
          whatWeDoTitle: "MM_Registration_Service4_WhatWeDo_Title_Page",
          whatWeDoDescription:
            "MM_Registration_Service4_WhatWeDo_Description_Page",
          businessPlanMarketTitle:
            "MM_Registration_Service4_BusinessPlanMarket_Title_Page",
          businessPlanMarketDescription:
            "MM_Registration_Service4_BusinessPlanMarket_Description_Page",
        };

      case "unique-expertise-in-europe":
        return {
          img: "/assets/images/services/29PaIGCEq10.jpg",
          title: "MM_Registration_Service5_Title",
          subTitle: "MM_Registration_Service5_SubTitle_Page",
          description: "MM_Registration_Service5_Description_Page",
          blueSection:
            "MM_Registration_Service5_BusinessPlanMarket_BlueSection_Page",
          whatWeDoTitle: "MM_Registration_Service5_WhatWeDo_Title_Page",
          whatWeDoDescription:
            "MM_Registration_Service5_WhatWeDo_Description_Page",
          businessPlanMarketTitle:
            "MM_Registration_Service5_BusinessPlanMarket_Title_Page",
          businessPlanMarketDescription:
            "MM_Registration_Service5_BusinessPlanMarket_Description_Page",
        };

      case "key-advantages-for-damaged-companies":
        return {
          img: "/assets/images/services/mhUsz2ezlXQ.jpg",
          title: "MM_Registration_Service6_Title",
          subTitle: "MM_Registration_Service6_SubTitle_Page",
          description: "MM_Registration_Service6_Description_Page",
          blueSection:
            "MM_Registration_Service6_BusinessPlanMarket_BlueSection_Page",
          whatWeDoTitle: "MM_Registration_Service6_WhatWeDo_Title_Page",
          whatWeDoDescription:
            "MM_Registration_Service6_WhatWeDo_Description_Page",
          businessPlanMarketTitle:
            "MM_Registration_Service6_BusinessPlanMarket_Title_Page",
          businessPlanMarketDescription:
            "MM_Registration_Service6_BusinessPlanMarket_Description_Page",
        };

      case "next-steps":
        return {
            img: "/assets/images/services/1920x9601.jpg",
            title: "MM_Registration_Service1_Title",
            subTitle: "MM_Registration_Service1_SubTitle_Page",
            description: "MM_Registration_Service1_Description_Page",
            blueSection:
              "MM_Registration_Service1_BusinessPlanMarket_BlueSection_Page",
            whatWeDoTitle: "MM_Registration_Service1_WhatWeDo_Title_Page",
            whatWeDoDescription:
              "MM_Registration_Service1_WhatWeDo_Description_Page",
            businessPlanMarketTitle:
              "MM_Registration_Service1_BusinessPlanMarket_Title_Page",
            businessPlanMarketDescription:
              "MM_Registration_Service1_BusinessPlanMarket_Description_Page",
        };
    }
  }

  getProject(id: string): any {
    switch (id) {
      case "key-facts-of-the-cartels":
        return {
          id,
          img: "/assets/images/projects/9cCeS9Sg6nU.jpg",
          title: "MM_Registration_Project1_Title_Page",
          subtitle: "MM_Registration_Project1_SubTitle_Page",
          description: "MM_Registration_Project1_Description_Page",
          blueSection: "MM_Registration_Project1_Description_BlueSection_Page",
          whatWeDoTitle: "MM_Registration_Project1_WhatWeDo_Title_Page",
          whatWeDoDescription:
            "MM_Registration_Project1_WhatWeDo_Description_Page",
          projectDetailsClient:
            "MM_Registration_Project1_ProjectDetails_Client_Page",
          projectDetailsLocation:
            "MM_Registration_Project1_ProjectDetails_Location_Page",
          projectDetailsCompleted:
            "MM_Registration_Project1_ProjectDetails_Completed_Page",
            navigation: {
              next: 'appels-procedures'
            }
        };

      case "appels-procedures":
        return {
          id,
          img: "/assets/images/projects/misAHv6YWk.jpg",
          title: "MM_Registration_Project2_Title_Page",
          subtitle: "MM_Registration_Project2_SubTitle_Page",
          description: "MM_Registration_Project2_Description_Page",
          blueSection: "MM_Registration_Project2_Description_BlueSection_Page",
          whatWeDoTitle: "MM_Registration_Project2_WhatWeDo_Title_Page",
          whatWeDoDescription:
            "MM_Registration_Project2_WhatWeDo_Description_Page",
          projectDetailsClient:
            "MM_Registration_Project2_ProjectDetails_Client_Page",
          projectDetailsLocation:
            "MM_Registration_Project2_ProjectDetails_Location_Page",
          projectDetailsCompleted:
            "MM_Registration_Project2_ProjectDetails_Completed_Page",
            navigation: {
              back: 'key-facts-of-the-cartels',
              next: 'affected-markets'
            }
        };

      case "affected-markets":
        return {
          id,
          img: "/assets/images/projects/s8gXH459728.jpg",
          title: "MM_Registration_Project3_Title_Page",
          subtitle: "MM_Registration_Project3_SubTitle_Page",
          description: "MM_Registration_Project3_Description_Page",
          blueSection: "MM_Registration_Project3_Description_BlueSection_Page",
          whatWeDoTitle: "MM_Registration_Project3_WhatWeDo_Title_Page",
          whatWeDoDescription:
            "MM_Registration_Project3_WhatWeDo_Description_Page",
          projectDetailsClient:
            "MM_Registration_Project3_ProjectDetails_Client_Page",
          projectDetailsLocation:
            "MM_Registration_Project3_ProjectDetails_Location_Page",
          projectDetailsCompleted:
            "MM_Registration_Project3_ProjectDetails_Completed_Page",
            navigation: {
              back: 'appels-procedures',
              next: 'cartel-members'
            }
        };

      case "cartel-members":
        return {
          id,
          img: "/assets/images/projects/2PJMDIgK9E.png",
          title: "MM_Registration_Project4_Title_Page",
          subtitle: "MM_Registration_Project4_SubTitle_Page",
          description: "MM_Registration_Project4_Description_Page",
          blueSection: "MM_Registration_Project4_Description_BlueSection_Page",
          whatWeDoTitle: "MM_Registration_Project4_WhatWeDo_Title_Page",
          whatWeDoDescription:
            "MM_Registration_Project4_WhatWeDo_Description_Page",
          projectDetailsClient:
            "MM_Registration_Project4_ProjectDetails_Client_Page",
          projectDetailsLocation:
            "MM_Registration_Project4_ProjectDetails_Location_Page",
          projectDetailsCompleted:
            "MM_Registration_Project4_ProjectDetails_Completed_Page",
            navigation: {
              back: 'affected-markets',
              next: 'limitation-periods'
            }
        };

      case "limitation-periods":
        return {
          id,
          img: "/assets/images/projects/UoIiVYka3VY.png",
          title: "MM_Registration_Project5_Title_Page",
          subtitle: "MM_Registration_Project5_SubTitle_Page",
          description: "MM_Registration_Project5_Description_Page",
          blueSection: "MM_Registration_Project5_Description_BlueSection_Page",
          whatWeDoTitle: "MM_Registration_Project5_WhatWeDo_Title_Page",
          whatWeDoDescription:
            "MM_Registration_Project5_WhatWeDo_Description_Page",
          projectDetailsClient:
            "MM_Registration_Project5_ProjectDetails_Client_Page",
          projectDetailsLocation:
            "MM_Registration_Project5_ProjectDetails_Location_Page",
          projectDetailsCompleted:
            "MM_Registration_Project5_ProjectDetails_Completed_Page",
            navigation: {
              back: 'cartel-members',
              next: 'how-to-prepare-your-information'
            }
        };

        case "how-to-prepare-your-information":
          return {
            id,
            img: "/assets/images/projects/PDPUPuNZ8.png",
            title: "MM_Registration_Project6_Title_Page",
            subtitle: "MM_Registration_Project6_SubTitle_Page",
            description: "MM_Registration_Project6_Description_Page",
            blueSection: "MM_Registration_Project6_Description_BlueSection_Page",
            whatWeDoTitle: "MM_Registration_Project6_WhatWeDo_Title_Page",
            whatWeDoDescription:
              "MM_Registration_Project6_WhatWeDo_Description_Page",
            projectDetailsClient:
              "MM_Registration_Project6_ProjectDetails_Client_Page",
            projectDetailsLocation:
              "MM_Registration_Project6_ProjectDetails_Location_Page",
            projectDetailsCompleted:
              "MM_Registration_Project6_ProjectDetails_Completed_Page",
              navigation: {
                back: 'limitation-periods'
              }
          };
    }
  }

  downloadBrochure(): Observable<Blob> {
    let language = localStorage.getItem("mm_landing_page");
    return this.http.get<Blob>(
      `${environment.cardboardURL}/api/cardboard/download-brochure/${language}`,
      { responseType: "blob" as "json" }
    );
  }

  getCompanyStatus(vat: string): Observable<any> {
    return this.http
      .get<any>(`${environment.cardboardURL}/api/cardboard/company/status/${vat}`)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            let id = Sentry.captureMessage(
              "General Error on GET:getCompanyStatus endpoint"
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
            });
          }
        })
      );
  }
}
