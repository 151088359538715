import { enableProdMode, importProvidersFrom } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

// import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

// Translation services
import { TranslationService } from "./app/services/translations/translation.service";
import { ITranslationService } from "./app/services/translations/translation";

// Cardboard service
import { CardboardService } from "./app/services/cardboard/cardboard.service";
import { ICardboardService } from "./app/services/cardboard/cardboard.interface";

// Helper service
import { HelperService } from "./app/services/helper/helper.service";
import { IHelperService } from "./app/services/helper/helper";

// Sentry

import * as Sentry from "@sentry/angular-ivy";
import { BrowserTracing } from "@sentry/browser";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppComponent } from "./app/app.component";
import { bootstrapApplication } from "@angular/platform-browser";
import { AppRoutingModule } from "./app/app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  TranslateLoader,
  TranslateModule,
  TranslateParser,
} from "@ngx-translate/core";
import { NgxTranslateDebugParser } from "ngx-translate-debug";
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from "ngx-ui-loader";
import { ICountryService } from "./app/services/country/country";
import { CountryService } from "./app/services/country/country.service";
import { IVATService } from "./app/services/vat/vat";
import { VATService } from "./app/services/vat/vat.service";
import { ITermConditionsService } from "./app/services/terms-conditions/terms-conditions";
import { TermsConditionsService } from "./app/services/terms-conditions/terms-conditions.service";
import { IRegisterService } from "./app/services/register/register";
import { RegisterService } from "./app/services/register/register.service";
import { IPrivacyPolicyService } from "./app/services/privacy-policy/privacy-policy";
import { PrivacyPolicyService } from "./app/services/privacy-policy/privacy-policy.service";
// import { AgmCoreModule } from "@agm/core";

Sentry.init({
  dsn: environment.SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [
        "localhost",
        environment.SENTRY_URL,
        environment.cardboardURL,
        environment.contractURL,
        environment.resourcesURL,
        environment.crmURL,
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
      unmask: [".swal2-popup", ".sentry-unmask", "[data-sentry-unmask]"],
    }),
  ],
  replaysSessionSampleRate: 0.7,
  replaysOnErrorSampleRate: 1.0,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.7,
});

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(HttpClientModule),

    importProvidersFrom(AppRoutingModule),
    importProvidersFrom(BrowserAnimationsModule),

    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
        parser: { provide: TranslateParser, useClass: NgxTranslateDebugParser },
      })
    ),

    importProvidersFrom(
      NgxUiLoaderModule.forRoot({
        fgsType: "three-bounce",
        fgsColor: "#137eff",
        pbColor: "#137eff",
        overlayColor: "rgb(18 36 58 / 80%)",
      })
    ),

    importProvidersFrom(
      NgxUiLoaderHttpModule.forRoot({
        showForeground: true,
        exclude: [],
      })
    ),

    { provide: ITranslationService, useClass: TranslationService },
    { provide: IHelperService, useClass: HelperService },
    { provide: ICardboardService, useClass: CardboardService },
    { provide: ICountryService, useClass: CountryService },
    { provide: IVATService, useClass: VATService },
    { provide: ITermConditionsService, useClass: TermsConditionsService },
    { provide: IRegisterService, useClass: RegisterService },
    { provide: IPrivacyPolicyService, useClass: PrivacyPolicyService }
  ],
}).catch((err) => console.error(err));

// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .catch((err) => console.error(err));

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.resourcesURL}/resources/`
  );
}
